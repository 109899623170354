




import 'codemirror/lib/codemirror.css'
import 'codemirror/addon//lint/lint.css'

import CodeMirror from 'codemirror'
import 'codemirror/addon/mode/simple'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/display/autorefresh'
import { codemirror } from 'vue-codemirror'

const bnglDefinitionModeConfig = {
  start: [
    {
      regex: /~\w*/,
      token: 'number',
    },
    {
      regex: /%\d*/,
      token: 'meta',
    },
    {
      regex: /![\d*|+|?]/,
      token: 'link',
    },
    {
      regex: /@\w*/,
      token: 'string',
    },
    {
      regex: ':',
      token: 'string',
    },
    {
      regex: /(\w*)(\()/,
      token: ['atom', null],
    },
    {
      regex: /(\()(\w*)/,
      token: [null, 'number'],
    },
  ],
}

const bnglParameterModeConfig = {
  start: [
    {
      regex: /-?(?:0|[1-9]\d*)(?:\.\d*)?(?:[eE][+-]?\d+)?/,
      token: 'number',
    },
    {
      regex: /[^/()*+-1-9]?([a-zA-Z_][a-zA-Z_0-9]*)/,
      token: 'variable-3',
    },
    {
      regex: /\(|\)/,
      token: 'qualifier',
    },
  ],
}

CodeMirror.defineSimpleMode('bngl-function', bnglParameterModeConfig)
CodeMirror.defineSimpleMode('bngl-parameter', bnglParameterModeConfig)

CodeMirror.defineSimpleMode('bngl-molecule', bnglDefinitionModeConfig)
CodeMirror.defineSimpleMode('bngl-species', bnglDefinitionModeConfig)
CodeMirror.defineSimpleMode('bngl-observable', bnglDefinitionModeConfig)
CodeMirror.defineSimpleMode('bngl-reaction', bnglDefinitionModeConfig)
CodeMirror.defineSimpleMode('bngl-diffusion', bnglDefinitionModeConfig)

export default {
  name: 'bngl-input',
  props: ['value', 'readOnly', 'entityType'],
  components: {
    codemirror,
  },
  mounted() {
    const cm = this.$refs.bnglCm.codemirror
    cm.setOption('extraKeys', {
      Tab: () => this.$emit('tab'),
      Enter: () => this.$emit('enter'),
    })
  },
  data() {
    return {
      cmOptions: {
        mode: `bngl-${this.entityType}`,
        theme: 'idea',
        scrollbarStyle: 'null',
        readOnly: this.readOnly,
        lint: false,
      },
    }
  },
  methods: {
    onCodeChange(code) {
      this.$emit('input', code)
    },
    refresh() {
      this.$refs.bnglCm.codemirror.refresh()
    },
    focus() {
      this.$refs.bnglCm.codemirror.focus()
    },
  },
}
